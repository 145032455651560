import React, { useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios'
import { useGlobalState } from '../state';

export default function CollectLoginInfo() {
    const [userRoles, setUserRoles] = useState([]);
    const [userAccessGroup, setUserAccessGroup] = useGlobalState('userAccessGroup')

    useEffect(() => {
        async function getUserInfo() {
            const response = await fetch('/.auth/me');
            const payload = await response.json();
            const { clientPrincipal } = payload;
            console.log(clientPrincipal)
            if (clientPrincipal == null) {
                localStorage.setItem('isAuthenticated', 'false');
                localStorage.setItem('username', " ");
                let event = {
                    user: "Unknown",
                    time: new Date(Date.now()).toISOString(),
                    action: "Failed response from Ping"
                }
                await axios.post('/api/send-splunk-data', {event}, 
                )
            }
            else if (response.status === 200) {
                localStorage.setItem('isAuthenticated', 'true');
                localStorage.setItem('username', clientPrincipal.userDetails);
                localStorage.setItem('roles', clientPrincipal.userRoles);
                localStorage.setItem('roles', clientPrincipal.userRoles);
                console.log(clientPrincipal)
                for(const i in clientPrincipal.claims){
                    if(clientPrincipal.claims[i].typ.includes("givenname")){
                        localStorage.setItem('firstname', clientPrincipal.claims[i].val)
                    }
                    if(clientPrincipal.claims[i].val == 'KMX-SSO-CCTWEBAPPS-CAF-USER-ROLE'){
                        setUserAccessGroup('KMX-SSO-CCTWEBAPPS-CAF-USER-ROLE')
                    }
                }
                let event = {
                    user: localStorage.getItem('username'),
                    time: new Date(Date.now()).toISOString(),
                    action: "Admin UI Login Successful"
                }
                let result = await axios.post('/api/send-splunk-data', {event}, 
                    {headers: {'Content-type':'application/x-www-form-urlenconded'}})
            }
            return clientPrincipal;
        }
        console.log(getUserInfo());

    })

    return (
     
        <div>
                <Redirect to="/configuration-cct-main" />                                                                            
        </div>
    )
}
