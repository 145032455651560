import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import { setGlobalState, useGlobalState } from '../state';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Backdrop, CircularProgress } from '@mui/material'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const EditButtons = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [editMode, setEditMode] = useGlobalState('editMode');
    const [menuData, setMenuData] = useState([]);
    const [lowScore, setLowScore] = useGlobalState('lowScore');
    const [medScore, setMedScore] = useGlobalState('medScore');
    const [highScore, setHighScore] = useGlobalState('highScore');
    const [initialPriority_Low, setInitialPriority_Low] = useGlobalState('initialPriority_Low');
    const [maxPriority_Low, setMaxPriority_Low] = useGlobalState('maxPriority_Low');
    const [initialPriority_Medium, setInitialPriority_Medium] = useGlobalState('initialPriority_Medium');
    const [rollOverWaitTime, setRollOverWaitTime] = useGlobalState('rollOverWaitTime');
    const [originalLowScore, setOriginalLowScore] = useGlobalState('originalLowScore');
    const [originalMedScore, setOriginalMedScore] = useGlobalState('originalMedScore');
    const [originalHighScore, setOriginalHighScore] = useGlobalState('originalHighScore');
    const [originalInitialPriority_Low, setOriginalInitialPriority_Low] = useGlobalState('originalInitialPriority_Low');
    const [originalMaxPriority_Low, setOriginalMaxPriority_Low] = useState('originalMaxPriority_Low');
    const [originalInitialPriority_Medium, setOriginalInitialPriority_Medium] = useGlobalState('originalInitialPriority_Medium');
    const [originalRollOverWaitTime, setOriginalRollOverWaitTime] = useGlobalState('originalRollOverWaitTime');
    const [max_num_executions, setMax_num_executions] = useGlobalState('max_num_executions');
    const [original_max_num_executions, set_original_max_num_executions] = useState('');
    const [max_num_sms_followups, setMax_num_sms_followups] = useGlobalState('max_num_sms_followups');
    const [original_num_sms_followups, set_original_num_sms_followups] = useState('');
    const [minutes_until_followup, setMinutes_until_followup] = useGlobalState('minutes_until_followup');
    const [original_minutes_until_followup, set_original_minutes_until_followup] = useState('');
    const [store_sms_start_offset, setStore_sms_start_offset] = useGlobalState('store_sms_start_offset');
    const [original_store_sms_start_offset, set_original_store_sms_start_offset] = useState('');
    const [store_sms_stop_offset, setStore_sms_stop_offset] = useGlobalState('store_sms_stop_offset');
    const [original_store_sms_stop_offset, set_original_store_sms_stop_offset] = useState('');
    const [seconds_failure_retry_wait, setSeconds_failure_retry_wait] = useGlobalState('seconds_failure_retry_wait');
    const [original_seconds_failure_retry_wait, set_original_seconds_failure_retry_wait] = useState('');
    const [max_failure_retries, setMax_failure_retries] = useGlobalState('max_failure_retries');
    const [original_max_failure_retries, set_original_max_failure_retries] = useState('');
    const [sms_phone_number_tracking_expiration, setSms_phone_number_tracking_expiration] = useGlobalState('sms_phone_number_tracking_expiration');
    const [original_sms_phone_number_tracking_expiration, set_original_sms_phone_number_tracking_expiration] = useState('');
    const [web_lead_auto_nuture_check_enabled, setWeb_lead_auto_nuture_check_enabled] = useGlobalState('web_lead_auto_nuture_check_enabled');
    const [original_web_lead_auto_nuture_check_enabled, set_original_web_lead_auto_nuture_check_enabled] = useState('');
    const [web_lead_non_routing_check_enabled, setWeb_lead_non_routing_check_enabled] = useGlobalState('web_lead_non_routing_check_enabled');
    const [original_web_lead_non_routing_check_enabled, set_original_web_lead_non_routing_check_enabled] = useState('');
    const [web_lead_self_progress_check_enabled, setWeb_lead_self_progress_check_enabled] = useGlobalState('web_lead_self_progress_check_enabled');
    const [original_web_lead_self_progress_check_enabled, set_original_web_lead_self_progress_check_enabled] = useState('');
    const [cafGlobalEnable, setCafGlobalEnable] = useGlobalState('cafGlobalEnable');
    const [originalCafGlobalEnable, setOriginalCafGlobalEnable] = useGlobalState('originalCafGlobalEnable');
    const [maxCallHoldingLower, setMaxCallHoldingLower] = useGlobalState('maxCallHoldingLower');
    const [originalMaxCallHoldingLower, setOriginalMaxCallHoldingLower] = useGlobalState('originalMaxCallHoldingLower');
    const [maxCallHoldingUpper, setMaxCallHoldingUpper] = useGlobalState('maxCallHoldingUpper');
    const [originalMaxCallHoldingUpper, setOriginalMaxCallHoldingUpper] = useGlobalState('originalMaxCallHoldingUpper');
    const [maxWaitLow, setMaxWaitLow] = useGlobalState('maxWaitLow');
    const [originalMaxWaitLow, setOriginalMaxWaitLow] = useGlobalState('originalMaxWaitLow');
    const [maxWaitUpper, setMaxWaitUpper] = useGlobalState('maxWaitUpper');
    const [originalMaxWaitUpper, setOriginalMaxWaitUpper] = useGlobalState('originalMaxWaitUpper');
    const [unoSliderDisable, setUnoSliderDisable] = React.useState('');
    const [taskLowThreshold, setTaskLowThreshold] = useGlobalState('taskLowThreshold');
    const [webLeadLowThreshold, setWebLeadLowThreshold] = useGlobalState('webLeadLowThreshold');
    const [disableSave, setDisableSave] = useGlobalState('disableSave');
    const [exclusionDisableSave, setExclusionDisableSave] = useGlobalState('exclusionDisableSave');
    const [imConfigData, setImConfigData] = useGlobalState('imConfigData');
    const [workFlowData, setWorkFlowData] = useGlobalState('workFlowData');
    const [configUpdateBody, setConfigUpdateBody] = useGlobalState('configUpdateBody');
    const [workFlowDataBody, setWorkFlowDataBody] = useGlobalState('workFlowDataBody');
    const [waitTimebody, setWaitTimebody] = useGlobalState('waitTimebody');
    const [phone_score_array, set_phone_score_array] = useState([])
    const [initial_priority_array, set_initial_priority_array] = useState([])
    const [max_priority_array, set_max_priority_array] = useState([])
    const [wait_time_array, set_wait_time_array] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [ruleIndex, setRuleIndex] = useGlobalState('ruleIndex')
    const [attributeIndex, setAttributeIndex] = useGlobalState('attributeIndex')
    const [midScoreThresholdTask, setMidScoreThresholdTask] = useGlobalState('midScoreThresholdTask')
    const [midScoreThresholdWebLead, setMidScoreThresholdWebLead] = useGlobalState('midScoreThresholdWebLead')
    const [unoPercentage, setUnoPercentage] = useGlobalState('unoPercentage');
    const [waitTimeThreshMin, setWaitTimeThreshMin] = useGlobalState('waitTimeThreshMin');
    const [successError, setSuccessError] = React.useState(false);
    const [submitError, setSubmitError] = React.useState(false);
    const [phoneScoreBody, setPhoneScoreBody] = useGlobalState('phoneScoreBody')
    const [newWaitTimeBody, setNewWaitTimeBody] = useGlobalState('newWaitTimeBody')
    const [unoBody, setUnoBody] = useGlobalState('unoBody')
    const [percentThresholdBody, setPercentThresholdBody] = useGlobalState('percentThresholdBody')
    const [inputArray, setInputArray] = useGlobalState('exclusionInputArray');
    const [outboundDataObject, setOutboundDataObject] = useGlobalState('outboundDataObject')
    const [exclusionInput, setExclusionInput] = useGlobalState('exclusionInput')
    const [validationChecker, setValidationChecker] = useGlobalState('validationChecker')
    const [invalidArr, setInvalidArr] = useGlobalState('invalidArr')
    const [notFoundDialer, setNotFoundDialer] = useGlobalState('notFoundDialer')
    const [newValues, setNewValues] = useGlobalState('newValues')
    const [errorArray, setErrorArray] = useGlobalState('errorArray')
    const [alertModal, setAlertModal] = useGlobalState('alertModal')
    const [exclusionDataError, setExclusionDataError] = useGlobalState('exclusionDataError')
    const [amountErr, setAmountErr] = useGlobalState('amountErr')
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [caf_wait_time_mins, set_caf_wait_time_mins] = useGlobalState('caf_wait_time_mins')
    const [in_queue_rollover_enabled, set_in_queue_rollover_enabled] = useGlobalState('in_queue_rollover_enabled')
    const [caf_wait_time_threshold_min_body, setcaf_wait_time_threshold_min_body] = useGlobalState('caf_wait_time_threshold_min_body')

    


    useEffect(() => {
        getImConfigData();
    }, []);

    const [task, setTask] = useGlobalState('task');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessError(false)
        setSubmitError(false)
        setAmountErr(false);
    };


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const getImConfigData = async () => {
        let params = { name: "all" }
        let result = await axios.get('/api/az_func_im_config', { params });
        sessionStorage.setItem("ImConfigData", JSON.stringify(result.data))
        let data = sessionStorage.getItem("ImConfigData")
        setGlobalState("imConfigData", JSON.parse(data))
        if (result.data === null || result.data === "") {
            result = { data: "No record found with that name" }
        }
        return result
    }

    const setValues = async () => {
        let result = await getImConfigData()
        for (const i in result.data) {
            if (result.data[i].name == "inbound_voice_general") {
                setLowScore(result.data[i].data.cec_sales_phone_scoring.team_assignment_scoring.low_threshold)
                setMedScore(result.data[i].data.cec_sales_phone_scoring.team_assignment_scoring.medium_threshold)
                setHighScore(result.data[i].data.cec_sales_phone_scoring.team_assignment_scoring.high_threshold)
                setInitialPriority_Low(result.data[i].data.cec_sales_phone_scoring.priority_scoring.groups.low.initial_priority)
                setInitialPriority_Medium(result.data[i].data.cec_sales_phone_scoring.priority_scoring.groups.medium.initial_priority)
                setMaxPriority_Low(result.data[i].data.cec_sales_phone_scoring.priority_scoring.groups.low.max_priority)
            } else if (result.data[i].name == "inbound_voice_rollover") {
                setWaitTimeThreshMin(result.data[i].data.department.sales.wait_time_threshold_minutes);
            }
        }
    }

    const setUnoValues = async () => {
        let result = await getImConfigData()
        for (const i in result.data) {
            if (result.data[i].name == "work_item_general") {
                setCafGlobalEnable(result.data[i].toggle.global_high_po_routing_enabled.enabled)
                setMaxCallHoldingLower(result.data[i].data.task.low_score_threshold)
                setMaxCallHoldingUpper(result.data[i].data.task.high_score_threshold)
                setMidScoreThresholdTask(result.data[i].data.task.mid_score_threshold)
                setMidScoreThresholdWebLead(result.data[i].data.web_lead.mid_score_threshold)
            }
        }
        let params = { name: "work_item_routing_override" }
        let percentResult = await axios.get('/api/az_func_im_config', { params });
        let response = {
            ab_test_current_percentage: null,
            rule_index: null,
            attribute_index: null
        }
        let rules = percentResult.data.data.rules
        let attributes = []
        for (let i in rules) {
            try {
                if (rules[i].cct_web_app_rule === 'project_uno') {
                    response.rule_index = i
                    setRuleIndex(i)
                    attributes = rules[i].attributes
                    break;
                }
            } catch (error) { }
        }
        for (let i in attributes) {
            try {
                if (attributes[i].cct_web_app_flag === "active") {
                    setUnoPercentage(attributes[i].val)
                    setAttributeIndex(i)
                }
            } catch (error) { }
        }
        unoEdits();
    }



    const getR2tdata = async () => {
        let params = { name: "work_item_r2t" }
        let result = await axios.get('/api/az_func_im_config', { params });

        if (result.data === null || result.data === "") {
            result = { data: "No record found with that name" }
        }
        return result
    }

    const getCafRoutingdata = async () => {
        let params = { name: "work_item_general" }
        let result = await axios.get('/api/az_func_im_config', { params });

        if (result.data === null || result.data === "") {
            result = { data: "No record found with that name" }
        }
        return result
    }


    const setR2tValues = async () => {
        let result = await getR2tdata();
        setGlobalState("max_num_executions", result.data.data.general.max_num_executions)
        setGlobalState("max_num_sms_followups", result.data.data.general.max_num_sms_followups)
        setGlobalState("minutes_until_followup", result.data.data.general.minutes_until_followup)
        setGlobalState("store_sms_start_offset", result.data.data.general.store_sms_start_offset)
        setGlobalState("store_sms_stop_offset", result.data.data.general.store_sms_stop_offset)
        setGlobalState("seconds_failure_retry_wait", result.data.data.general.seconds_failure_retry_wait)
        setGlobalState("max_failure_retries", result.data.data.general.max_failure_retries)
        setGlobalState("sms_phone_number_tracking_expiration", result.data.data.general.sms_phone_number_tracking_expiration)
    }

    const setR2tLegacyCntrls = async () => {
        let result = await getR2tdata();
        setGlobalState("web_lead_auto_nuture_check_enabled", result.data.data.legacy_controls.web_lead_auto_nuture_check_enabled)
        setGlobalState("web_lead_non_routing_check_enabled", result.data.data.legacy_controls.web_lead_non_routing_check_enabled)
        setGlobalState("web_lead_self_progress_check_enabled", result.data.data.legacy_controls.web_lead_self_progress_check_enabled)
    }

    const setCafRoutingValues = async () => {
        let result = await getCafRoutingdata();
        setGlobalState("cafGlobalEnable", result.data.toggle.global_high_po_routing_enabled.enabled)
        setGlobalState("maxCallHoldingLower", result.data.data.task.low_score_threshold)
        setGlobalState("maxCallHoldingUpper", result.data.data.task.high_score_threshold)
        unoEdits();
    }
    

    async function outbound_contact_data() {
        editModeOn();
       }

    const editModeOn = () => {
        setExclusionDisableSave(true)
        setEditMode(true);
        unoEdits();
        setUnoValues();
        clearUpdateBodyValues();
        setOriginalLowScore(lowScore);
        setOriginalMedScore(medScore);
        setOriginalHighScore(highScore);
        setOriginalInitialPriority_Low(initialPriority_Low);
        setOriginalMaxPriority_Low(maxPriority_Low);
        setOriginalInitialPriority_Medium(initialPriority_Medium);
        setOriginalRollOverWaitTime(rollOverWaitTime);
        set_original_max_num_executions(max_num_executions);
        set_original_num_sms_followups(max_num_sms_followups);
        set_original_minutes_until_followup(minutes_until_followup);
        set_original_store_sms_start_offset(store_sms_start_offset);
        set_original_store_sms_stop_offset(store_sms_stop_offset);
        set_original_seconds_failure_retry_wait(seconds_failure_retry_wait);
        set_original_max_failure_retries(max_failure_retries);
        set_original_sms_phone_number_tracking_expiration(sms_phone_number_tracking_expiration);
        set_original_web_lead_auto_nuture_check_enabled(web_lead_auto_nuture_check_enabled);
        set_original_web_lead_non_routing_check_enabled(web_lead_non_routing_check_enabled);
        set_original_web_lead_self_progress_check_enabled(web_lead_self_progress_check_enabled);
    }

    async function cancelChanges() {
        setConfigUpdateBody([]);
        setValues();
        setUnoValues();
        clearUpdateBodyValues();
        setGlobalState("editMode", false);
        setExclusionInput('')
        setValidationChecker(false)
        setInputArray([])
        setInvalidArr([])
        setNotFoundDialer(false)
        getCafWaitTimeMins ()
    }
    const unoEdits = () => {
        if (editMode && cafGlobalEnable) {
            setUnoSliderDisable(false);
        } else {
            setUnoSliderDisable(true);
        }
    }

    const clearUpdateBodyValues = async () => {
        phoneScoreBody.newValues = {}
        phoneScoreBody.originalValues = {}
        newWaitTimeBody.newValues = {}
        newWaitTimeBody.originalValues = {}
        unoBody.newValues = {}
        unoBody.originalValues = {}
        percentThresholdBody.newValues = {}
        percentThresholdBody.originalValues = {}
        workFlowDataBody.scores = []
        waitTimebody.times = []
    }

    const handleDisplayOpen = () => {
        setDialogOpen(true);
        if(caf_wait_time_threshold_min_body.updateFlag){
            put_caf_wait_time_mins()
        }
        setErrorArray([])
      };
      const handleDisplayClose = () => {
        setDialogOpen(false);
      };

    const postWfm = async () => {
        setIsLoading(true)

        if (phoneScoreBody.updateFlag) {
            configUpdateBody.push(phoneScoreBody)
        }
        if (newWaitTimeBody.updateFlag) {
            configUpdateBody.push(newWaitTimeBody)
        }

        if (unoBody.updateFlag) {
            configUpdateBody.push(unoBody)
        }
        if (percentThresholdBody.updateFlag) {
            configUpdateBody.push(percentThresholdBody)
        }

        let newPhoneScoreBody = {}
        let newVoiceRolloverBody = {}
        let newWorkItemGenBody = {}
        let newWorkItemOverrideBody = {}
        let oldPhoneScoreBody = {}
        let oldVoiceRolloverBody = {}
        let oldWorkItemGenBody = {}
        let oldWorkItemOverrideBody = {}
        let phoneDocSyncFlag = false;
        let rolloverDocSyncFlag = false;
        let newWiGenDocSyncFlag = false;
        let oldWiDocSyncFlag = false;


        for (const i in configUpdateBody) {
            if (configUpdateBody[i].collectionName == "inbound_voice_rollover") {
                let params = { name: "inbound_voice_rollover" }
                let result = await axios.get('/api/az_func_im_config', { params });
                newVoiceRolloverBody = JSON.stringify(result.data)
                for (const i in imConfigData) {
                    if (imConfigData[i].name == "inbound_voice_rollover") {
                        oldVoiceRolloverBody = JSON.stringify(imConfigData[i])
                    }
                }
                if (newVoiceRolloverBody != oldVoiceRolloverBody) {
                    rolloverDocSyncFlag = true;
                }
            }
            if (configUpdateBody[i].collectionName == "inbound_voice_general") {
                let params = { name: "inbound_voice_general" }
                let result = await axios.get('/api/az_func_im_config', { params });
                newPhoneScoreBody = JSON.stringify(result.data)
                for (const i in imConfigData) {
                    if (imConfigData[i].name == "inbound_voice_general") {
                        oldPhoneScoreBody = JSON.stringify(imConfigData[i])
                    }
                }
                if (newPhoneScoreBody != oldPhoneScoreBody) {
                    phoneDocSyncFlag = true;
                }
            }
            if (configUpdateBody[i].collectionName == "work_item_general") {
                let params = { name: "work_item_general" }
                let result = await axios.get('/api/az_func_im_config', { params });
                newWorkItemGenBody = JSON.stringify(result.data)
                for (const i in imConfigData) {
                    if (imConfigData[i].name == "work_item_general") {
                        oldWorkItemGenBody = JSON.stringify(imConfigData[i])
                    }
                }
                if (newWorkItemGenBody != oldWorkItemGenBody) {
                    newWiGenDocSyncFlag = true;
                }
            }
            if (configUpdateBody[i].collectionName == "work_item_routing_override") {
                let params = { name: "work_item_routing_override" }
                let result = await axios.get('/api/az_func_im_config', { params });
                newWorkItemOverrideBody = JSON.stringify(result.data)
                for (const i in imConfigData) {
                    if (imConfigData[i].name == "work_item_routing_override") {
                        oldWorkItemOverrideBody = JSON.stringify(imConfigData[i])
                    }
                }
                if (newWorkItemOverrideBody != oldWorkItemOverrideBody) {
                    oldWiDocSyncFlag = true;
                }
            }
        }

        if (phoneDocSyncFlag == true || rolloverDocSyncFlag == true || newWiGenDocSyncFlag == true || oldWiDocSyncFlag == true) {
            alert("There have been recent updates. Press OK to get updated values.")
            setValues();
            setUnoValues();
            clearUpdateBodyValues();
            setConfigUpdateBody([]);
            phoneDocSyncFlag = false;
            rolloverDocSyncFlag = false;
            newWiGenDocSyncFlag = false;
            oldWiDocSyncFlag = false
            setEditMode(false)
            setIsLoading(false)
        } else if (phoneDocSyncFlag == false || rolloverDocSyncFlag == false || newWiGenDocSyncFlag == false || oldWiDocSyncFlag == false) {
            for (const i in configUpdateBody) {
                await axios.put('/api/az_func_im_config', configUpdateBody[i])
            }

            setGlobalState("editMode", false);
            setConfigUpdateBody([]);
            clearUpdateBodyValues();
            setIsLoading(false);
            setSuccessError(true);
        }
    }

    const postR2TValues = async () => {
        let getR2tValueResult = await getR2tdata();
        if (getR2tValueResult.data.data.general.max_num_executions != original_max_num_executions ||
            getR2tValueResult.data.data.general.max_num_sms_followups != original_num_sms_followups ||
            getR2tValueResult.data.data.general.minutes_until_followup != original_minutes_until_followup ||
            getR2tValueResult.data.data.general.store_sms_start_offset != original_store_sms_start_offset ||
            getR2tValueResult.data.data.general.store_sms_stop_offset != original_store_sms_stop_offset ||
            getR2tValueResult.data.data.general.seconds_failure_retry_wait != original_seconds_failure_retry_wait ||
            getR2tValueResult.data.data.general.max_failure_retries != original_max_failure_retries ||
            getR2tValueResult.data.data.general.sms_phone_number_tracking_expiration != original_sms_phone_number_tracking_expiration
        ) {
            alert("There has been a recent change to the current R2T values. Please click ok to receive the latest data.");
            await setR2tValues();
            setGlobalState("editMode", false);
        }
        else {
            alert("Changes have been made successfully.")
            let updateBody = [{
                profileName: "work_item_r2t",
                userName: localStorage.getItem("username"),
                workItems: {
                    "data.general.max_num_executions": max_num_executions,
                    "data.general.max_num_sms_followups": max_num_sms_followups,
                    "data.general.minutes_until_followup": minutes_until_followup,
                    "data.general.store_sms_start_offset": store_sms_start_offset,
                    "data.general.store_sms_stop_offset": store_sms_stop_offset,
                    "data.general.seconds_failure_retry_wait": seconds_failure_retry_wait,
                    "data.general.max_failure_retries": max_failure_retries,
                    "data.general.sms_phone_number_tracking_expiration": sms_phone_number_tracking_expiration,
                    "data.legacy_controls.web_lead_auto_nuture_check_enabled": web_lead_auto_nuture_check_enabled,
                    "data.legacy_controls.web_lead_non_routing_check_enabled": web_lead_non_routing_check_enabled,
                    "data.legacy_controls.web_lead_self_progress_check_enabled": web_lead_self_progress_check_enabled
                },
                originalR2tValues: [
                    { R2tProfile: "max_num_executions", value: original_max_num_executions },
                    { R2tProfile: "max_num_sms_followups", value: original_num_sms_followups },
                    { R2tProfile: "minutes_until_followup", value: original_minutes_until_followup },
                    { R2tProfile: "store_sms_start_offset", value: original_store_sms_start_offset },
                    { R2tProfile: "store_sms_stop_offset", value: original_store_sms_stop_offset },
                    { R2tProfile: "seconds_failure_retry_wait", value: original_seconds_failure_retry_wait },
                    { R2tProfile: "max_failure_retries", value: original_max_failure_retries },
                    { R2tProfile: "sms_phone_number_tracking_expiration", value: original_sms_phone_number_tracking_expiration },
                    { R2tProfile: "web_lead_auto_nuture_check_enabled", value: original_web_lead_auto_nuture_check_enabled },
                    { R2tProfile: "web_lead_non_routing_check_enabled", value: original_web_lead_non_routing_check_enabled },
                    { R2tProfile: "web_lead_self_progress_check_enabled", value: original_web_lead_self_progress_check_enabled }
                ]
            }]

            let data = updateBody
            let putR2tResult = await axios.put('/api/az_func_r2t_work_item', { data })

            if (putR2tResult.data === null || putR2tResult.data === "") {
                putR2tResult = { data: "Cannot update the R2T values correctly" }
            }
            setGlobalState("editMode", false);
        }
    }

    async function loan_number_validation() {
        for(const i in inputArray){
          inputArray[i] = inputArray[i].trim()
        }
         setIsLoading(false)
}

async function put_caf_wait_time_mins() {
    setIsLoading(true)
    let update_body = caf_wait_time_threshold_min_body
    let result = await axios.put('/api/az_func_im_config', update_body)
    if(!result.status == 200){
        setSubmitError(true)
    }
    setIsLoading(false)
    setSuccessError(true)
    setEditMode(false)
}

async function put_caf_call_exclusion() {
    setDialogOpen(false);
    loan_number_validation()
    setIsLoading(true)

    let getOBcontacts =  await axios.put('/api/az_func_outbound_contacts', {
        new_values: inputArray
      })
    if(getOBcontacts.status !== 200){
        setExclusionDataError(true)
    }
    if(errorArray){
     for(const i in getOBcontacts.data.results){
        if(!getOBcontacts.data.results[i].success){
            setErrorArray([])
            if(!errorArray.includes(getOBcontacts.data.results[i].result)){
                errorArray.push(getOBcontacts.data.results[i].result)
            }
            setErrorArray(errorArray)                   
            handleDisplayClose()                   
            setAlertModal(true)
            setIsLoading(false)                
        }else if(getOBcontacts.data.results[i].success){
            console.log("true")
            setEditMode(false)
            handleDisplayClose()
            setSuccessError(true)
            setInputArray([])
            setExclusionInput('')
            setValidationChecker(false)
            setIsLoading(false)
        }
    }
    }

}

async function getCafWaitTimeMins (){
    let params = {name:'ivr_caf_routing_general'}
    let caf_wait_time_data = await axios.get('/api/az_func_im_config', {params})
    set_caf_wait_time_mins(caf_wait_time_data.data.data.in_queue_rollover.CAF_Customer_Service_Past_Due_Voice_IB_ENG.wait_time_threshold_minutes)
    set_in_queue_rollover_enabled(caf_wait_time_data.data.data.in_queue_rollover.CAF_Customer_Service_Past_Due_Voice_IB_ENG.enabled)
}

    const postCafValues = async () => { 
      if(caf_wait_time_threshold_min_body.updateFlag){
        put_caf_wait_time_mins()
      }
      if(inputArray.length > 0){
        put_caf_call_exclusion()
      }
    }

    return (
        <div>
            <Grid conainer>
                <Grid item xs={12}>
                    {editMode === true && window.location.pathname === '/configuration-wfm' ?
                        <Button startIcon={<SaveIcon />} onClick={postWfm} disabled={disableSave} color="success" variant="contained" sx={{ marginBottom: '13px', height: 25, position: "fixed", top: 35, right: 160, zIndex: 2 }}>Save</Button>
                        : ""}
                    {editMode === true && window.location.pathname === '/configuration-wfm' ?
                        <Button startIcon={<ClearIcon />} onClick={cancelChanges} variant="contained" color="error" sx={{ marginBottom: '13px', height: 25, position: "fixed", top: 35, right: 35, zIndex: 2 }}>Cancel</Button>
                        : ""}
                    {editMode === false && window.location.pathname === '/configuration-wfm' ?
                        <Button startIcon={<EditIcon />} onClick={editModeOn} variant="contained" className="confirmationButton" sx={{ marginBottom: '13px', height: 25, position: "fixed", top: 35, right: 35, zIndex: 2000 }}>Edit</Button>
                        : ""}
                    {editMode === true && window.location.pathname === '/configuration-cfr' ?
                        <Button onClick={postR2TValues} variant="contained" sx={{ color: 'white', backgroundColor: '#053361', marginBottom: '13px', marginRight: '25px', height: 25 }}>Save and Exit</Button>
                        : ""}
                    {editMode === true && window.location.pathname === '/configuration-cfr' ?
                        <Button onClick={cancelChanges} variant="contained" sx={{ color: 'white', backgroundColor: '#053361', marginBottom: '13px', marginRight: '25px', height: 25 }}>Cancel</Button>
                        : ""}
                    {editMode === false && window.location.pathname === '/configuration-cfr' ?
                        <Button onClick={editModeOn} variant="contained" sx={{ color: '#053361', backgroundColor: '#FFFF00', marginBottom: '13px', marginRight: '25px', height: 25 }}>Edit</Button>
                        : ""}
                    {editMode === true && window.location.pathname === '/configuration-caf' ?
                        <Button startIcon={<SaveIcon />} disabled={disableSave} onClick={postCafValues} color="success" variant="contained" sx={{ marginBottom: '13px', height: 25, position: "fixed", top: 35, right: 160, zIndex: 2 }}>Save</Button>
                        : ""}
                    {editMode === true && window.location.pathname === '/configuration-caf' ?
                        <Button startIcon={<ClearIcon />} onClick={cancelChanges} variant="contained" color="error" sx={{ marginBottom: '13px', height: 25, position: "fixed", top: 35, right: 35, zIndex: 2 }}>Cancel</Button>
                        : ""}
                    {editMode === false && window.location.pathname === '/configuration-caf' ?
                        <Button startIcon={<EditIcon />} onClick={outbound_contact_data} variant="contained" className="confirmationButton" sx={{ marginBottom: '13px', height: 25, position: "fixed", top: 35, right: 35, zIndex: 2000 }}>Edit</Button>
                        : ""}
                    {editMode === true && window.location.pathname === '/configuration-cr' ?
                        <Button variant="contained" sx={{ color: 'white', backgroundColor: '#053361', marginBottom: '13px', marginRight: '25px', height: 25 }}>Save and Exit</Button>
                        : ""}
                    {editMode === true && window.location.pathname === '/configuration-cr' ?
                        <Button onClick={cancelChanges} variant="contained" sx={{ color: 'white', backgroundColor: '#053361', marginBottom: '13px', marginRight: '25px', height: 25 }}>Cancel</Button>
                        : ""}
                    {editMode === false && window.location.pathname === '/configuration-cr' ?
                        <Button onClick={editModeOn} variant="contained" sx={{ color: '#053361', backgroundColor: '#FFFF00', marginBottom: '13px', marginRight: '25px', height: 25 }}>Edit</Button>
                        : ""}
                </Grid>
            </Grid>
            <Snackbar open={successError} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
                <Alert onClose={handleClose} variant="filled" severity="success" sx={{ width: '100%' }}>
                    Changes have been made successfully
                </Alert>
            </Snackbar>
            <Snackbar open={submitError} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
                <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
                    Oops! There was an issue. Please Try again.
                </Alert>
            </Snackbar>
            <Snackbar open={exclusionDataError} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
                <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
                    There was an issue connecting to the database. Please try again later.
                </Alert>
            </Snackbar>
            <Snackbar open={amountErr} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
                <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
                Only 100 Entries Allowed Per Submission.
                </Alert>
            </Snackbar> 
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to submit these loan numbers?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <List dense={true} sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {inputArray.map((value) => (
               <ListItem
              key={value}
              disableGutters
        >
          <ListItemText sx={{ color: 'black' }} primary={`${value}`} />
        </ListItem>
      ))}
    </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisplayClose}>Cancel</Button>
          <Button onClick={postCafValues} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
        </div>
    )
}

export default EditButtons;