import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DrawerCustom from '../component/Drawer';
import { setGlobalState, useGlobalState } from '../state';
import CallExclusionForm from './CallExclusionForm';
import EditButtons from './EditButtons'
import CAFWaitTimeThresholdMin from './CAFWaitTimeThresholdMin';
const drawerWidth = 240;


export default function OutboundContacts() {
  const [userAccessGroup, setUserAccessGroup] = useGlobalState('userAccessGroup')
  const roles = localStorage.getItem("roles")

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <DrawerCustom />
      <EditButtons sx={{ marginTop: '-3%' }} />
    <Grid container>
      <Grid item xs={12} sx={{marginBottom:'2%'}}>
      <Accordion sx={{ width: "100%", marginBottom:'2%' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="uiSubHeaderFont" id="accordionLabel">
              Call Exclusion
            </Typography>
            <Tooltip
              title="CAF Loan numbers that are excluded from the dialer list no longer fits the dialer requirements for a phone call. "
              sx={{ paddingTop: "0", color: '#febd12' }}
            >
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails sx={{ width: "100%" }}>
          <CallExclusionForm />
          </AccordionDetails>
        </Accordion>
        </Grid>
        {userAccessGroup == 'KMX-SSO-CCTWEBAPPS-CAF-USER-ROLE' ?
        <Grid item xs={12}>
        <CAFWaitTimeThresholdMin />
        </Grid>
        : ""}        
        </Grid>
    </Box >
  );
}