import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import '../index.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListSubheader from '@mui/material/ListSubheader';
import Popover from '@mui/material/Popover';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import AccountCircle from '@mui/icons-material/AccountCircle';
import axios from 'axios';
import LogoutIcon from '@mui/icons-material/Logout';
import CarMaxIcon from '../CARMAXCCT_Icon_Yellow_Wordless.png';
import Image from "../drawerBck.jpeg";
import Grid from '@mui/material/Grid';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import AzureIcon from "../azureIcon.png";
import GenesysIcon from "../genesysIcon.webp";
import CarmaxSmallIcon from "../carmaxIconSmall.png"; 
import HomeIcon from '@mui/icons-material/Home';
import CarMaxLogo from "../CarMax-Logo_White.png"


const drawerWidth = 260;



export default function DrawerCustom() {

  useEffect(() => {
    menuItems();
  })

  const styles = {
    gradBack: {
      backgroundImage: `url(${Image})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',

      position: 'fixed',
    }
  }

  const [menuData, setMenuData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const firstName = localStorage.getItem("firstname")
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;


  const handleClick = () => {
    setOpen(!open);
  };

  async function clearSession() {
    let userName = localStorage.getItem('username')
    localStorage.clear();
    let event = {
      user: userName,
      time: new Date(Date.now()).toISOString(),
      action: "Admin UI Log-Out Successful",
    }
    window.location.replace("/.auth/logout?post_logout_redirect_uri=/Logout")
    let result = await axios.post('/api/send-splunk-data', { event },
      { headers: { 'Content-type': 'application/x-www-form-urlenconded' } })
  }

  const logoutRedirect = async () => {
    window.location.href = "/auth/logout";
    window.location.href = "/Logout";
    }
  
  const menuItems = async () => {
    try {
      let roleData = localStorage.getItem('roles')
      let roleArray = roleData.split(",")

      if (menuData.length === 0) {
        for (const i in roleArray) {
          switch (roleArray[i]) {
            case "admin":
              menuData.push({ name: "WFM Configurations", url: "/configuration-wfm" })
              menuData.push({ name: "CFR Configurations", url: "/configuration-cfr" })
              menuData.push({ name: "CAF Configurations", url: "/configuration-caf" })
              menuData.push({ name: "CR Configurations", url: "/configuration-cr" })
              break;
            case "wfm":
              if (!roleArray[i].includes("WFM Configurations")) {
                menuData.push({ name: "WFM Configurations", url: "/configuration-wfm" })
              }
              break;
            case "cfr":
              if (!roleArray[i].includes("CFR Configurations")) {
                menuData.push({ name: "CFR Configurations", url: "/configuration-cfr" })
              }
              break;
            case "caf":
              if (!roleArray[i].includes("CAF Configurations")) {
                menuData.push({ name: "CAF Configurations", url: "/configuration-caf" })
              }
              break;
            case "cr":
              if (!roleArray[i].includes("CR Configurations")) {
                menuData.push({ name: "CR Configurations", url: "/configuration-cr" })
              }
              break;
            default:
              break;
          }
        }
      }
    }
    catch (err) {
      console.log(err)
    }
  }


  return (
    <Box sx={{ display: 'flex' }} >
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#191919',
            boxShadow:"-3px 0 5px 0 #555"
          },
        }}
        variant="permanent"
        anchor="left"
      >


        <Toolbar sx={{ marginTop: '7%', marginLeft: '-5%', marginBottom: '6%' }}>
          <Grid container xs={12}>
            <Grid item xs={3} sx={{ marginRight: 1  }}>
              <img src={CarMaxIcon} className='inline'></img>
            </Grid>
            <Grid item xs={8} sx={{ padding: 0 }}>
              <Typography sx={{ color: '#fafbfc', float: 'left', fontSize: 22, marginTop: '5%' }} className='inline2'>CCT Web App</Typography>
              <Typography sx={{ fontSize: '65%', fontStyle: 'oblique', color: 'white', marginLeft: '1.5%', float: 'left' }}>Powered By:</Typography>
              <img src={AzureIcon} className='topIcons'></img>
              <img src={GenesysIcon} className='topIcons'></img>
              <img src={CarmaxSmallIcon} className='topIcons'></img>
            </Grid>
           
            <Grid container xs={12}>

            </Grid>
          </Grid>

        </Toolbar>
        <Divider />
        <center>
            <ListItem>    
           <center>
            <ListItemText sx={{ color: 'white', marginLeft: 2 }}>
            <Typography variant='h6'>Welcome, {firstName} <LogoutIcon aria-describedby={id} variant="contained" onClick={handlePopoverClick} sx={{ fontSize: 13 }} /></Typography>
            <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Link onClick={clearSession}><Typography sx={{ p: 2 }}> Log Out</Typography></Link>
      </Popover> 
            </ListItemText>
           </center>
            </ListItem> 
        </center>
        <List
          sx={{ width: '100%', maxWidth: 360}}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon sx={{ color: '#febd12' }} />
            </ListItemIcon>
            <Link to={"/"}><ListItemText sx={{color: 'white'}} primary="Home" /></Link>
          </ListItemButton>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <DisplaySettingsIcon sx={{ color: '#febd12' }} />
            </ListItemIcon>
            <ListItemText sx={{color: 'white'}} primary="Configurations" />
            {open ? <ExpandLess sx={{color: 'white'}} /> : <ExpandMore sx={{color: 'white'}} />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {menuData.map((menuDatas, text, index) => (
                <ListItem ListItemButton key={index}>
                   <Link to={menuDatas.url} style={{ textDecoration: 'none', color: "white" }}><ListItemText primary={menuDatas.name} sx={{zIndex:1}} /></Link>  
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
        <List sx={{ position: 'fixed', bottom: '0px'}}>
    <ListItem>
      <img src={CarMaxLogo} className='drawerBottomLogo'></img>
    </ListItem>
  </List> 
  

      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
      </Box>
    </Box>
  );
}







{/* <List>

        </List>
        <Divider />
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Menu Items
            </ListSubheader>
          }
        >
          <ListItemButton>
            <ListItemIcon>
              <InsightsIcon sx={{ color: '#000e77' }} />
            </ListItemIcon>
            <ListItemText primary="Monitoring" />
          </ListItemButton>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <DisplaySettingsIcon sx={{ color: '#000e77' }} />
            </ListItemIcon>
            <ListItemText primary="Configurations" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

              {menuData.map((menuDatas, text, index) => (
                <ListItem button key={index}>
                  <ListItemText primary={text} />
                  <Link to={menuDatas.url} style={{ textDecoration: 'none', color: "black" }}>{menuDatas.name}</Link>
                </ListItem>

              ))}
            </List>
          </Collapse>
        </List>
        <List>
        </List>
        <div>
  <List>
    <LogoutIcon sx={{ position: 'fixed', bottom: '0px', color: '#000e77' }} />
    <ListItem onClick={clearSession} sx={{ textAlign: 'left' }}>
      <Link className= 'logOutText' to="/.auth/logout?post_logout_redirect_uri=/Logout"> Log Out</Link>  
    </ListItem>
  </List> 
</div> */}